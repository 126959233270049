/* THIS FILE CREATES
Styles for the "supporting" pages for workspace: Access Lost, invite, login, and privacy policy
 */
/* This is the vendor import that contains items needed for most of the "main" files to build out correctly
it can be loaded multiple times because most of these files should not bulk up the final built file.
*/
/* Can't use this because we're using root-defined colors

@function set-secondary-font-color($color) {
	@if ($color == #fff) {
		@return #a2a2a2;
	} @else if (lightness($color) > 50) {
		@return darken($color, 28%)
	} @else {
		@return lighten($color, 20%);
	}
}
 */
/* IE11 */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  html,
body,
div.page,
div.lt-ie7 {
    height: auto;
    min-height: 100%;
  }
}
#main-content {
  min-height: calc(100vh - 53px);
}

#footer,
#engages-footer {
  background: #7B7B7B;
  padding: 20px;
  color: #fff;
}
#footer p,
#engages-footer p {
  margin: 0;
}
#footer a,
#engages-footer a {
  color: #fff;
}
#footer > .box-wc,
#engages-footer > .box-wc {
  max-width: 1920px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  #footer > .box-wc,
#engages-footer > .box-wc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
#footer #footer-copyright,
#engages-footer #footer-copyright {
  font-size: 0.65rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  flex: 0 0 auto;
  text-align: center;
}

#footer-logo {
  flex: 0 0 auto;
  max-width: 48%;
  margin-bottom: 30px;
}
@media (min-width: 1024px) {
  #footer-logo {
    margin: 0;
  }
}
#footer-logo img {
  height: 53px;
  width: auto;
}

.copyable {
  cursor: url(/_design/admin/default/icons/cursor-copy.png) 2 2, copy;
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}

.copyable-alert-box {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10000;
}

.copyable-alert-box:empty {
  display: none;
}

.alert-dismissible {
  word-break: break-all;
}
.alert-dismissible .close {
  cursor: pointer;
  background: transparent;
  border: none;
  line-height: 1;
  padding: 0 15px;
  height: 100%;
  font-size: 0;
}
.alert-dismissible .close:after {
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
  font-size: 1.125rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}